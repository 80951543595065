import React from 'react'
import useLocale from '@logdoc/l10n';
import LayoutDefault from "../layouts";
import * as classes from '../sass/style.module.scss'
import { L10nProvider } from '@logdoc/l10n';
import clearPathname from '../utils/link'

const View = () => {
    return (
        <L10nProvider>
            <ViewLogs />
        </L10nProvider>
    )
}

export const ViewLogs = () => {
    const t = useLocale()
    return (
        <LayoutDefault>
            <section className={classes.sec}>
                <div className={classes.secBreadcrumbs}>
                    <span>{t('view.breadcrumbs.1')}</span>
                    <span>/</span>
                    <span>{t('view.breadcrumbs.2')}</span>
                </div>
                <div className={classes.secDescription}>
                    {t('view.description.p1')}<br/><br/>

                    <p>
                        {t('view.description.p2')}<br/><br/>
                        <a href="#stream">{t('view.description.p3')}</a><br/>
                        <a href="#history">{t('view.description.p4')}</a><br/>
                        <a href="#watchdog">{t('view.description.p5')}</a><br/>
                    </p>
                </div>
                <h5 className={classes.secSubtitleSecond}>
                    <a name="stream"/>
                    {t('view.description.p6')}
                </h5>
                <div className={classes.secBlock}>
                    <div className={classes.secDescription}>
                        <p>{t('view.description.p7')} <span style={{color: '#fff'}}>tail -F log.txt</span></p>
                        <p>{t('view.description.p8')}
                            (см. <a href={clearPathname("/access")}>{t('view.description.p9')}</a>) {t('view.description.p10')}</p>
                        <p>{t('view.description.p11')}</p>
                    </div>
                </div>
                <div className={classes.secBlock}>
                    <strong>{t('view.description.p12')}</strong>
                    <div className={classes.secDescription}>
                        <p>{t('view.description.p13')}</p>

                        <p>{t('view.description.p14')} <a href={clearPathname("/gather")}>{t('view.description.p15')}</a>.</p>

                        <p>{t('view.description.p16')} <em>"{t('view.description.p17')}"</em>. {t('view.description.p18')} <strong>"+"</strong> {t('view.description.p19')}.</p>
                        <p>{t('view.description.p20')}</p>
                        <p>{t('view.description.p21')} <strong>+</strong> {t('view.description.p22')}</p>
                        <p>{t('view.description.p23')}</p>
                        <p>{t('view.description.p24')}</p>
                        <p>{t('view.description.p25')}</p>
                    </div>
                </div>
                <h5 className={classes.secSubtitleSecond}>
                    <a name="history"/>
                    {t('view.description.p26')}
                </h5>
                <div className={classes.secBlock}>
                    <div className={classes.secDescription}>
                        <p>{t('view.description.p27')}</p>
                        <p>{t('view.description.p28')}</p>
                    </div>
                </div>
                <div className={classes.secBlock}>
                    <strong>{t('view.description.p29')}</strong>
                    <div className={classes.secDescription}>
                        <p>{t('view.description.p30')}</p>
                        <p>{t('view.description.p31')} <em>"{t('view.description.p32')}"</em>. {t('view.description.p33')} <strong>"+"</strong> {t('view.description.p34')}.</p>
                        <p>{t('view.description.p35')} >= "ERROR".</p>
                        <p>{t('view.description.p36')} </p>
                        <p>{t('view.description.p37')}</p>
                        <p>{t('view.description.p38')}</p>
                    </div>
                </div>
                <h5 className={classes.secSubtitleSecond}>
                    <a name="watchdog"/>
                    {t('view.description.p39')}
                </h5>
                <div className={classes.secBlock}>
                    <div className={classes.secDescription}>
                        <p>{t('view.description.p40')}</p>
                        <p>{t('view.description.p41')} <a href={clearPathname("/plugins")}>{t('view.description.p42')}</a>.</p>
                    </div>
                </div>
                <div className={classes.secBlock}>
                    <strong>{t('view.description.p422')}</strong>
                    <div className={classes.secDescription}>
                        <p>{t('view.description.p43')}</p>
                        <p>{t('view.description.p44')} <em>"{t('view.description.p45')}"</em>. {t('view.description.p46')} <strong>"+"</strong> {t('view.description.p47')}</p>
                        <p>{t('view.description.p48')} >= "SEVERE".</p>
                        <p>{t('view.description.p49')}</p>
                        <p>{t('view.description.p50')}</p>
                        <p>{t('view.description.p51')}</p>
                        <p>{t('view.description.p52')}</p>
                    </div>
                </div>
            </section>
        </LayoutDefault>
    );
};

export default View;
